import { FC } from 'react';
import styled from 'styled-components';

import { SelectableSummaryItemCheckbox } from './selectable-summary-item.styles';

import MoreOptions from '../more-options/more-options.component';
import ShowView from '../show-view/show-view.component';
import SvgIcon from '../svg-icon/svg-icon.component';

import { colors } from '~/lib/colors';

interface ISelectedActions {
  selectedCount: number;
  resetSelection: () => void;
  onSelectAll: () => void;
  onSelectAllOnPage: () => void;
  isAllSelected: boolean;
  isAllOnPageSelected: boolean;
}

const SelectedActions: FC<ISelectedActions> = ({
  selectedCount,
  resetSelection,
  onSelectAll,
  onSelectAllOnPage,
  isAllSelected,
  isAllOnPageSelected,
  children,
}) => {
  return (
    <SelectedActionsContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectableSummaryItemCheckbox
          alwaysRelative={true}
          name="all-on-page"
          onChange={!isAllOnPageSelected ? onSelectAllOnPage : resetSelection}
          checked={isAllOnPageSelected}
          value="all"
        />

        {!!selectedCount && <p>{selectedCount}</p>}
      </div>

      <ShowView when={!!selectedCount}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SvgIcon
            style={{ marginRight: '1.5rem', cursor: 'pointer' }}
            iconName="close"
            black
            onClick={resetSelection}
          />

          <MoreOptions
            trigger={
              <SvgContainer>
                <SvgIcon
                  iconName="more-options"
                  black
                  style={{ transform: 'rotate(90deg)' }}
                />
              </SvgContainer>
            }
          >
            {children}

            <ShowView when={!isAllSelected}>
              <MoreOptions.ListItem onClick={onSelectAll}>
                Select all
              </MoreOptions.ListItem>
            </ShowView>
          </MoreOptions>
        </div>
      </ShowView>
    </SelectedActionsContainer>
  );
};

const SelectedActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding-right: 2rem;

  @media only screen and (max-width: 37.5em) {
    padding-right: 0;
  }
`;

const SvgContainer = styled.div`
  background-color: ${colors.white1};
  padding: 5px;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default SelectedActions;
