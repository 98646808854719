import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import type { AddAnimalArgs } from './useAddAnimal';

type BulkUpdateAnimalsArgs = {
  ids: number[];
  data: Partial<AddAnimalArgs>;
};

const bulkUpdateAnimals = async (data: BulkUpdateAnimalsArgs) => {
  const response = await apiV1.patch('/animals/batch-update/', data);

  return response.data;
};

const useBulkUpdateAnimals = (closeModal: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(bulkUpdateAnimals, {
    mutationKey: ReactQueryKeys.BULK_UPDATE_ANIMALS,
    onSuccess: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_ANIMALS);

      queryClient.invalidateQueries(ReactQueryKeys.GET_ALL_ANIMAL_IDS);

      toast.success('Animals updated successfully');

      closeModal();
    },
    onError: (error: AxiosError) => {
      toast.error(error.response?.data?.message);
    },
  });
};

export default useBulkUpdateAnimals;
