import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

const getAllAnimalIds = async (
  filters = {},
  signal?: AbortSignal
): Promise<number[]> => {
  const url = `/animals/ids/?${queryString.stringify(filters)}`;

  const response = await apiV1.get(url, { signal: signal ?? undefined });

  return response.data;
};

const useGetAllAnimalIds = (filters = {}) => {
  return useQuery(
    [ReactQueryKeys.GET_ALL_ANIMAL_IDS, filters],
    ({ signal }) => getAllAnimalIds(filters, signal),
    {
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        toast.error(err.message);
      },
    }
  );
};

export default useGetAllAnimalIds;
