import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > button {
    border-radius: 0;
    min-width: fit-content;
    width: 13rem;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
