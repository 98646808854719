import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const SelectableSummaryItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`;

export const SelectableSummaryItemCheckbox = styled.input.attrs({
  type: 'checkbox',
})<{ alwaysRelative?: boolean }>`
  appearance: none;
  aspect-ratio: 1;
  width: 1.6rem;
  height: 1.6rem;
  border: 1.5px solid;
  border-color: ${colors.primary};
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  margin-right: 2rem;
  padding: 0;
  background-color: transparent;
  outline: transparent dashed 1.5px;
  outline-offset: 2px;
  transition-property: outline-color background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:focus-visible {
    outline-color: ${colors.black};
  }

  &:checked {
    background-color: ${colors.primary};
  }

  &:checked:after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 1rem;
    border: solid;
    border-color: ${colors.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 0.5px;
    left: 4.5px;

    @media only screen and (max-width: 20em) {
      width: 4px;
      height: 8px;
      border-width: 0 1.5px 1.5px 0;
      top: 0;
      left: 3.5px;
    }
  }

  ${({ alwaysRelative }) =>
    !alwaysRelative &&
    `
  @media only screen and (max-width: 37.5em) {
    position: absolute;
    top: 9px;
    right: -8px;
    z-index: 1;
  }`}
`;
