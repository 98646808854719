import styled from 'styled-components';

import { colors } from '~/lib/colors';

export interface ITagContainerStyleProps {
  danger?: boolean;
  sold?: boolean;
}

export const TagContainer = styled.span<ITagContainerStyleProps>`
  display: inline-block;
  padding: 2px 8px;
  color: ${colors.black};
  background-color: ${colors.gray4};
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.3rem;

  &:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  ${({ sold }) =>
    sold && `background-color: ${colors.gray3}; color: ${colors.white};`}

  ${({ danger }) =>
    danger && `background-color: ${colors.error}; color: ${colors.white};`}
`;
