import { FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { ButtonsContainer } from './top-buttons.styles';
import { FormInputContainer } from '../form/form.styles';

import Button from '../button/button.component';
import SvgIcon from '../svg-icon/svg-icon.component';

import useMediaQuery from '~/hooks/useMediaQuery';

type TopButtonsProps = {
  options: { route: string; label: string }[];
};

const TopButtons: FC<TopButtonsProps> = ({ options }) => {
  const [isMounted, setIsMounted] = useState(false);

  const router = useRouter();

  const { pathname } = router;

  const isViewportGreaterThan460px = useMediaQuery('(min-width: 28.75em)');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted && isViewportGreaterThan460px ? (
    <ButtonsContainer>
      {options.map((option) => (
        <Button
          key={option.route}
          semitransparentBg={pathname !== option.route}
          onClick={() => router.push(option.route)}
        >
          {option.label}
        </Button>
      ))}
    </ButtonsContainer>
  ) : (
    <FormInputContainer withSvg>
      <select
        name="routes"
        value={pathname}
        id="routes"
        onChange={({ target: { value } }) => router.push(value)}
      >
        {options.map((option) => (
          <option key={option.route} value={option.route}>
            {option.label}
          </option>
        ))}
      </select>

      <SvgIcon style={{ top: '35%' }} iconName="chevron-down" />
    </FormInputContainer>
  );
};

export default TopButtons;
