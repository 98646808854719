import { FC } from 'react';

import { ITagContainerStyleProps, TagContainer } from './tag.styles';

interface TagProps extends ITagContainerStyleProps {
  text: string;
}

const Tag: FC<TagProps> = ({ text, ...otherProps }) => {
  return <TagContainer {...otherProps}>{text}</TagContainer>;
};

export default Tag;
