/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useMemo } from 'react';
import ASelect from 'react-select/async';
import styled from 'styled-components';

import {
  customStyles,
  ICustomSelectProps,
  DropdownIndicator,
} from '../select/select.component';

import InputErrorMessage from '../input-error-message/input-error-message.component';

import debounce from '~/utils/debounce';

const AsyncSelectContainer = styled(ASelect)<Omit<ICustomSelectProps, 'id'>>`
  width: 100%;
`;

type AsyncSelectProps = Omit<ICustomSelectProps, 'options'> & {
  loadOptions: (
    inputValue: string,
    callback: (options: unknown) => void
  ) => void | Promise<void>;
};

const AsyncSelect: FC<AsyncSelectProps> = ({
  id,
  inputRef,
  error,
  loadOptions,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState('');

  const debouncedLoadOptions = useMemo(
    () => debounce(loadOptions, 1000),
    [loadOptions]
  );

  return (
    <>
      <AsyncSelectContainer
        inputValue={inputValue}
        styles={customStyles}
        loadOptions={debouncedLoadOptions}
        cacheOptions
        onInputChange={(newValue: string) => setInputValue(newValue)}
        ref={inputRef}
        components={{ DropdownIndicator }}
        placeholder="Select one..."
        isSearchable
        isClearable={false}
        inputId={id}
        instanceId={id}
        isError={Boolean(error)}
        loadingMessage={() => 'Searching...'}
        closeMenuOnSelect
        defaultMenuIsOpen={false}
        {...otherProps}
      />

      {error?.message && (
        <InputErrorMessage message={error?.message as string} />
      )}
    </>
  );
};

export default AsyncSelect;
