import { FC, ReactNode } from 'react';
import Link from 'next/link';

import { Btn } from '../button/button.styles';
import {
  SelectableSummaryItemCheckbox,
  SelectableSummaryItemContainer,
} from './selectable-summary-item.styles';
import { SummaryItem } from '~/layouts/general-layout/general-layout.styles';

interface ISelectableSummaryItem {
  children: ReactNode;
  href: string;
  linkText?: string;
  onInputChange: (value: string) => void;
  isSelected: boolean;
  inputValue: string;
}

const SelectableSummaryItem: FC<ISelectableSummaryItem> = ({
  children,
  href,
  linkText = 'View details',
  onInputChange,
  isSelected,
  inputValue,
}) => {
  return (
    <SelectableSummaryItemContainer>
      <SelectableSummaryItemCheckbox
        name={`item-${inputValue}`}
        onChange={({ target: { value } }) => onInputChange(value)}
        checked={isSelected}
        value={inputValue}
      />

      <SummaryItem>
        {children}

        <Link href={href} passHref>
          <Btn as="a">{linkText}</Btn>
        </Link>
      </SummaryItem>
    </SelectableSummaryItemContainer>
  );
};

export default SelectableSummaryItem;
