import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import type { InventoryItem } from '../../types/inventory-item.type';

export const getInventoryItems = getAll<InventoryItem>(
  apiV1,
  '/inventory-items/'
);

const useGetInventoryItems = (page = 1, limit = 10, filters = {}) => {
  return useQuery(
    [ReactQueryKeys.GET_INVENTORY_ITEMS, page, limit, filters],
    ({ signal }) => getInventoryItems(page, limit, filters, signal),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetInventoryItems;
