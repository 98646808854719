import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { Form, FormGroup, FormInputContainer } from '../form/form.styles';

import SvgIcon from '../svg-icon/svg-icon.component';
import Button from '../button/button.component';
import InputErrorMessage from '../input-error-message/input-error-message.component';
import AsyncSelect from '../select/async-select.component';
import AsyncCreatableSelect from '../select/async-creatable-select.component';

import type { SelectOptionType } from '../select/select.component';

import useStore from '~/store';

import useGetAnimals from '~/react-query/queries/useGetAnimals';
import useGetOrganizations from '~/react-query/queries/useGetOrganizations';
import useBulkUpdateAnimals from '~/react-query/mutations/useBulkUpdateAnimals';

import { DATETIME_FORMAT } from '~/utils/constants';
import getSelectOptions from '~/utils/getSelectOptions';
import getISOString from '~/utils/getISOString';
import { loadAnimals, loadOrganizations } from '~/utils/selectOptionLoaders';

interface IAddAnimalFormInputTypes {
  name?: string;
  sex?: string;
  organization?: (SelectOptionType & { __isNew__?: boolean }) | null;
  weaning_date?: string;
  birth_date?: string;
  birth_weight?: string | null;
  description?: string;
  farm_born?: string;
  dam?: SelectOptionType | null;
  sire?: SelectOptionType | null;
  active?: string;
}

type IBatchUpdateAnimalsProps = {
  closeModal: () => void;
  animals: number[];
};

const BatchUpdateAnimals: FC<IBatchUpdateAnimalsProps> = ({
  closeModal,
  animals,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddAnimalFormInputTypes>({
    defaultValues: {
      name: '',
      sex: '',
      organization: null,
      weaning_date: '',
      birth_date: '',
      birth_weight: '',
      description: '',
      farm_born: '',
      dam: null,
      sire: null,
      active: '',
    },
  });

  const currentUser = useStore((state) => state.currentUser);

  const { data: maleAnimals, isLoading: isGettingMaleAnimals } = useGetAnimals(
    1,
    10,
    { sex: 'male' }
  );

  const { data: femaleAnimals, isLoading: isGettingFemaleAnimals } =
    useGetAnimals(1, 10, { sex: 'female' });

  const { data: organizations, isLoading: isGettingOrganizations } =
    useGetOrganizations(1, 10);

  const { mutate: bulkUpdateAnimals, isLoading: isBulkUpdatingAnimals } =
    useBulkUpdateAnimals(closeModal);

  const onSubmit: SubmitHandler<IAddAnimalFormInputTypes> = (data) => {
    const payload: {
      name?: string;
      sex?: string;
      organization?: number;
      weaning_date?: string;
      birth_date?: string;
      birth_weight?: string | null;
      description?: string;
      farm_born?: boolean;
      dam?: number;
      sire?: number;
      active?: boolean;
      herd: number;
      new_organization?: { name: string };
    } = {
      name: data.name || undefined,
      sex: data.sex || undefined,
      birth_weight: data.birth_weight || undefined,
      herd: currentUser?.herds?.[0] as number,
      birth_date: getISOString(data?.birth_date as string) || undefined,
      weaning_date: getISOString(data?.weaning_date as string) || undefined,
      farm_born: data?.farm_born
        ? data?.farm_born === 'true'
          ? true
          : false
        : undefined,
      dam: data?.dam ? Number(data?.dam?.value) : undefined,
      sire: data?.sire ? Number(data?.sire?.value) : undefined,
      active: data?.active
        ? data?.active === 'true'
          ? true
          : false
        : undefined,
      organization: undefined,
      new_organization: undefined,
    };

    if (data?.organization?.__isNew__) {
      payload.new_organization = { name: data?.organization.value as string };
    } else {
      payload.organization = Number(data?.organization?.value) || undefined;
    }

    bulkUpdateAnimals({ ids: animals, data: payload });
  };

  return (
    <Form rightAlignedButton onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <FormInputContainer isInputInvalid={!!errors?.name}>
          <label htmlFor="name">Name tag</label>

          <input type="text" id="name" {...register('name')} />

          {errors?.name && (
            <InputErrorMessage message={errors?.name?.message as string} />
          )}
        </FormInputContainer>

        <FormInputContainer withSvg isInputInvalid={!!errors?.sex}>
          <label htmlFor="sex">Sex</label>

          <select id="sex" {...register('sex')}>
            <option value="" disabled>
              Select one
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>

          <SvgIcon iconName="chevron-down" />

          {errors?.sex && (
            <InputErrorMessage message={errors?.sex?.message as string} />
          )}
        </FormInputContainer>
      </FormGroup>

      <FormInputContainer isInputInvalid={!!errors?.organization}>
        <label htmlFor="organization">Organization</label>

        <Controller
          control={control}
          name="organization"
          render={({ field, fieldState: { error } }) => (
            <AsyncCreatableSelect
              inputRef={field.ref}
              id="organization"
              name="organization"
              loadOptions={(inputValue, callback) =>
                loadOrganizations({ search: inputValue }, callback)
              }
              defaultOptions={getSelectOptions(organizations?.results) ?? []}
              error={error}
              onChange={field.onChange}
              value={field.value}
              isDisabled={isGettingOrganizations}
              placeholder={
                isGettingOrganizations
                  ? 'Getting organizations...'
                  : 'Start typing or select one...'
              }
              formatCreateLabel={(inputValue: string) =>
                `Create new organization: "${inputValue}"`
              }
            />
          )}
        />
      </FormInputContainer>

      <FormGroup>
        <FormInputContainer>
          <label htmlFor="birth_date">Birth date</label>

          <input
            type="datetime-local"
            id="birth_date"
            max={dayjs().format(DATETIME_FORMAT)}
            {...register('birth_date', {
              required: false,
              max: {
                value: dayjs().format(DATETIME_FORMAT),
                message: 'Birth date cannot be in the future',
              },
            })}
          />
        </FormInputContainer>

        <FormInputContainer>
          <label htmlFor="birth_weight">Birth weight</label>

          <input
            type="text"
            id="birth_weight"
            {...register('birth_weight', { required: false })}
          />
        </FormInputContainer>
      </FormGroup>

      <FormGroup>
        <FormInputContainer isInputInvalid={!!errors?.dam}>
          <label htmlFor="dam">Dam</label>

          <Controller
            control={control}
            name="dam"
            render={({ field, fieldState: { error } }) => (
              <AsyncSelect
                id="dam"
                inputRef={field.ref}
                loadOptions={(input, callback) =>
                  loadAnimals({ sex: 'female', search: input }, callback)
                }
                defaultOptions={getSelectOptions(femaleAnimals?.results) ?? []}
                error={error}
                isSearchable
                value={field.value}
                onChange={field.onChange}
                placeholder={
                  isGettingFemaleAnimals
                    ? 'Getting male animals...'
                    : 'Select one...'
                }
                isDisabled={isGettingFemaleAnimals}
              />
            )}
          />
        </FormInputContainer>

        <FormInputContainer isInputInvalid={!!errors?.sire}>
          <label htmlFor="sire">Sire</label>

          <Controller
            control={control}
            name="sire"
            render={({ field, fieldState: { error } }) => (
              <AsyncSelect
                id="sire"
                inputRef={field.ref}
                loadOptions={(input, callback) =>
                  loadAnimals({ sex: 'male', search: input }, callback)
                }
                defaultOptions={getSelectOptions(maleAnimals?.results) ?? []}
                error={error}
                isSearchable
                value={field.value}
                onChange={field.onChange}
                placeholder={
                  isGettingMaleAnimals
                    ? 'Getting male animals...'
                    : 'Select one...'
                }
                isDisabled={isGettingMaleAnimals}
              />
            )}
          />
        </FormInputContainer>
      </FormGroup>

      <FormInputContainer>
        <label htmlFor="weaning_date">Weaning date</label>

        <input
          type="datetime-local"
          id="weaning_date"
          max={dayjs().format(DATETIME_FORMAT)}
          {...register('weaning_date', {
            required: false,
            max: {
              value: dayjs().format(DATETIME_FORMAT),
              message: 'Birth date cannot be in the future',
            },
          })}
        />
      </FormInputContainer>

      <FormGroup>
        <FormInputContainer withSvg>
          <label htmlFor="farm_born">Is farm born?</label>

          <select id="farm_born" {...register('farm_born')}>
            <option value="" disabled>
              Select one
            </option>

            <option value="true">Yes</option>

            <option value="false">No</option>
          </select>

          <SvgIcon iconName="chevron-down" />
        </FormInputContainer>

        <FormInputContainer withSvg>
          <label htmlFor="active">Is active?</label>

          <select id="active" {...register('active')}>
            <option value="" disabled>
              Select one
            </option>

            <option value="true">Yes</option>

            <option value="false">No</option>
          </select>

          <SvgIcon iconName="chevron-down" />
        </FormInputContainer>
      </FormGroup>

      <FormInputContainer>
        <label htmlFor="description">Description</label>

        <textarea
          id="description"
          {...register('description', { required: false })}
        ></textarea>
      </FormInputContainer>

      <Button isLoading={isBulkUpdatingAnimals} loadingText="Saving...">
        Save
      </Button>
    </Form>
  );
};

export default BatchUpdateAnimals;
