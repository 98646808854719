import dynamic from 'next/dynamic';
import { useRef, useState } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';
import { useRouter } from 'next/router';

import type { NextPageWithLayout } from '../_app';

import {
  DashboardItemFooter,
  InfoTags,
  InfoTagsContainer,
  PageTitleActionsContainer,
  SummaryItemTextContainer,
} from '~/layouts/general-layout/general-layout.styles';

import GeneralLayout from '~/layouts/general-layout/general-layout.component';
import Tag from '~/components/tag/tag.component';
import Modal from '~/components/modal/modal.component';
import ModalContentLoader from '~/components/loaders/modal-content-loader.component';
import DashboardItemLoader from '~/components/dashboard-item-loader/dashboard-item-loader.component';
import ShowView from '~/components/show-view/show-view.component';
import PageNavigator from '~/components/page-navigator/page-navigator.component';
import HerdLayout from '~/layouts/herd-layout/herd-layout.component';
import MoreOptions from '~/components/more-options/more-options.component';
import Search from '~/components/search/search.component';
import SelectableSummaryItem from '~/components/selectable-summary-item/selectable-summary-item.component';
import SelectedActions from '~/components/selectable-summary-item/selected-actions.component';
import BulkUpdateAnimals from '~/components/bulk-update-animals/bulk-update-animals.component';

import routes from '~/helpers/routes';

import useRedirectToInitialPage from '~/hooks/useRedirectToPage';

import useGetAnimals from '../../react-query/queries/useGetAnimals';
import useGetAllAnimalIds from '~/react-query/queries/useGetAllAnimalIds';

const AddAnimal = dynamic(
  () => import('~/components/add-animal/add-animal.component'),
  {
    loading: () => <ModalContentLoader />,
  }
);

const BulkAddAnimals = dynamic(
  () => import('~/components/bulk-add-animals/bulk-add-animals.component'),
  {
    loading: () => <ModalContentLoader />,
  }
);

const AddAnimalsToExistingHerdTag = dynamic(
  () =>
    import(
      '~/components/add-animals-to-existing-herd-tag/add-animals-to-existing-herd-tag.component'
    ),
  { loading: () => <ModalContentLoader /> }
);

const AddAnimalsToNewHerdTag = dynamic(
  () =>
    import(
      '~/components/add-animals-to-new-herd-tag/add-animals-to-new-herd-tag.component'
    ),
  { loading: () => <ModalContentLoader /> }
);

const HerdsPage: NextPageWithLayout = () => {
  const router = useRouter();

  const query = router.query;

  const [page, setPage] = useState(Number(query.page) || 1);
  const [filters, setFilters] = useState({});
  const [searchInput, setSearchInput] = useState(query?.search || '');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const addAnimalRef = useRef<PopupActions>(null);
  const bulkAddAnimalsRef = useRef<PopupActions>(null);
  const bulkUpdateAnimalsRef = useRef<PopupActions>(null);
  const addToHerdTagRef = useRef<PopupActions>(null);
  const createHerdTagRef = useRef<PopupActions>(null);

  const {
    data: animals,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAnimals(page, 10, {
    ...filters,
    search: searchInput,
  });

  const allIdsOnPage = animals?.results?.map((animal) => animal.id) || [];

  const { data: allAnimalIds } = useGetAllAnimalIds({
    ...filters,
    search: searchInput,
  });

  useRedirectToInitialPage({
    isSuccess,
    page,
    setPage,
    dataCount: animals?.count ?? 0,
  });

  return (
    <>
      <PageTitleActionsContainer>
        <h1>Animals ({animals?.count ?? 0})</h1>

        <MoreOptions>
          <MoreOptions.ListItem onClick={() => addAnimalRef?.current?.open()}>
            Add animal
          </MoreOptions.ListItem>

          <MoreOptions.ListItem
            onClick={() => bulkAddAnimalsRef?.current?.open()}
          >
            Bulk add animals
          </MoreOptions.ListItem>
        </MoreOptions>
      </PageTitleActionsContainer>

      <Search
        onSearchInputChange={(value) => {
          if (value !== searchInput) setSelectedIds([]);
          setSearchInput(value);
        }}
        onFiltersChange={(searchFilters) => {
          if (JSON.stringify(filters) !== JSON.stringify(searchFilters)) {
            setSelectedIds([]);
          }
          setFilters(searchFilters);
        }}
        searchPlaceholder="Search for name_tag, eid_tag, ear_tag, reg_id, organization, or description"
        filters={[
          { name: 'sex', value: 'male', label: 'male', type: 'checkbox' },
          { name: 'sex', value: 'female', label: 'female', type: 'checkbox' },
          { name: 'alive', value: 'true', label: 'alive', type: 'checkbox' },
          { name: 'alive', value: 'false', label: 'dead', type: 'checkbox' },
          { name: 'sold', value: 'true', label: 'sold', type: 'checkbox' },
          { name: 'sold', value: 'false', label: 'not sold', type: 'checkbox' },
          { name: 'active', value: 'true', label: 'active', type: 'checkbox' },
          {
            name: 'active',
            value: 'false',
            label: 'inactive',
            type: 'checkbox',
          },
          {
            name: 'farm_born',
            value: 'true',
            label: 'farm born',
            type: 'checkbox',
          },
          {
            name: 'farm_born',
            value: 'false',
            label: 'not farm born',
            type: 'checkbox',
          },
          {
            name: 'pasture_assigned',
            value: 'true',
            label: 'in pasture',
            type: 'checkbox',
          },
          {
            name: 'pasture_assigned',
            value: 'false',
            label: 'not in pasture',
            type: 'checkbox',
          },
          {
            name: 'birth_date__range',
            value: '',
            label: 'Birth date',
            type: 'date',
          },
          {
            name: 'weaning_date__range',
            value: '',
            label: 'Weaning date',
            type: 'date',
          },
        ]}
      />

      <div>
        <DashboardItemLoader
          isLoading={isLoading || isFetching}
          loadingText="Getting animals..."
          hasResult={!!animals?.count}
        >
          <SelectedActions
            selectedCount={selectedIds.length}
            resetSelection={() => setSelectedIds([])}
            onSelectAll={() => setSelectedIds(allAnimalIds as number[])}
            onSelectAllOnPage={() =>
              setSelectedIds((prev) =>
                Array.from(new Set([...prev, ...allIdsOnPage]))
              )
            }
            isAllSelected={selectedIds.length === Number(animals?.count)}
            isAllOnPageSelected={allIdsOnPage?.every((el) =>
              selectedIds.includes(el)
            )}
          >
            <MoreOptions.ListItem
              onClick={() => addToHerdTagRef?.current?.open()}
            >
              Add to herd tag
            </MoreOptions.ListItem>

            <MoreOptions.ListItem
              onClick={() => createHerdTagRef?.current?.open()}
            >
              Create herd tag
            </MoreOptions.ListItem>

            <MoreOptions.ListItem
              onClick={() => bulkUpdateAnimalsRef?.current?.open()}
            >
              Update selected
            </MoreOptions.ListItem>
          </SelectedActions>

          {animals?.results?.map((animal) => (
            <SelectableSummaryItem
              key={animal?.id}
              href={routes.animal(animal?.id as number)}
              isSelected={selectedIds.includes(animal?.id)}
              onInputChange={(val) => {
                const value = Number(val);

                if (selectedIds.includes(value)) {
                  setSelectedIds((prev) => [
                    ...prev.filter((el) => el !== value),
                  ]);

                  return;
                }

                setSelectedIds((prev) => [...prev, value]);
              }}
              inputValue={String(animal?.id)}
            >
              <InfoTagsContainer>
                <SummaryItemTextContainer>
                  <p>
                    <span>Name tag: </span>

                    <span>{animal?.name}</span>
                  </p>

                  <ShowView when={!!animal?.eid_tag}>
                    <p>
                      <span>EID Tag: </span>

                      <span style={{ textTransform: 'initial' }}>
                        {animal?.eid_tag}
                      </span>
                    </p>
                  </ShowView>

                  <ShowView when={!!animal?.ear_tag}>
                    <p>
                      <span>Ear tag: </span>

                      <span style={{ textTransform: 'initial' }}>
                        {animal?.ear_tag}
                      </span>
                    </p>
                  </ShowView>
                </SummaryItemTextContainer>

                <InfoTags>
                  <ShowView when={!!animal?.death}>
                    <Tag text="Dead" danger />
                  </ShowView>

                  <ShowView when={animal?.sold}>
                    <Tag text="Sold" sold />
                  </ShowView>

                  <ShowView when={!!animal?.sex}>
                    <Tag text={animal?.sex} />
                  </ShowView>

                  <ShowView when={!animal?.farm_born}>
                    <Tag danger text={'Not farm born'} />
                  </ShowView>

                  <ShowView when={!animal?.active}>
                    <Tag danger text="Inactive" />
                  </ShowView>

                  <ShowView when={!!animal?.pasture_assigned}>
                    <Tag text="In pasture" />
                  </ShowView>
                </InfoTags>
              </InfoTagsContainer>
            </SelectableSummaryItem>
          ))}
        </DashboardItemLoader>

        {(animals?.count ?? 0) > 10 && (
          <DashboardItemFooter>
            <PageNavigator
              currentPage={page}
              totalPages={Math.ceil((animals?.count ?? 0) / 10)}
              setPage={setPage}
            />
          </DashboardItemFooter>
        )}
      </div>

      {/* Modals */}
      <Modal ref={addAnimalRef} trigger={<></>} modalTitle="Add animal">
        {(close) => <AddAnimal closeModal={close} />}
      </Modal>

      <Modal
        ref={bulkAddAnimalsRef}
        modalTitle="Bulk add animals"
        trigger={<></>}
      >
        {(close) => <BulkAddAnimals closeModal={close} />}
      </Modal>

      <Modal
        ref={bulkUpdateAnimalsRef}
        modalTitle={`Update ${selectedIds.length} animals`}
        trigger={<></>}
      >
        {(close) => (
          <BulkUpdateAnimals closeModal={close} animals={selectedIds} />
        )}
      </Modal>

      <Modal modalTitle="Update herd tag" ref={addToHerdTagRef} trigger={<></>}>
        {(close) => (
          <AddAnimalsToExistingHerdTag
            closeModal={close}
            animals={selectedIds}
          />
        )}
      </Modal>

      <Modal modalTitle="Add herd tag" ref={createHerdTagRef} trigger={<></>}>
        {(close) => (
          <AddAnimalsToNewHerdTag closeModal={close} animals={selectedIds} />
        )}
      </Modal>
    </>
  );
};

HerdsPage.getLayout = (page) => {
  return (
    <GeneralLayout pageTitle="Your animals">
      <HerdLayout>{page}</HerdLayout>
    </GeneralLayout>
  );
};

export default HerdsPage;
