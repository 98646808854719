import type { FC, ReactNode } from 'react';

import TopButtons from '~/components/top-buttons/top-buttons.component';

import routes from '~/helpers/routes';

interface IHerdLayout {
  children: ReactNode;
}

const HerdLayout: FC<IHerdLayout> = ({ children }) => {
  return (
    <>
      <TopButtons
        options={[
          { route: routes.animals(), label: 'Animals' },
          { route: routes.herdTags(), label: 'Herd tags' },
        ]}
      />

      {children}
    </>
  );
};

export default HerdLayout;
